import React, {useState} from "react"
import {graphql, useStaticQuery} from "gatsby";
import AboutNavigation from "../../components/about/aboutNavigation";
import InnerLayout from "../../components/global/innerLayout"
import PageTitle from "../../components/global/pageTitle";
import Select from "../../components/global/select";
import "./press-releases.scss"

const PressReleasesPage = () => {

    const data = useStaticQuery(graphql`
        {
          craft {
            page: entry(section: "pressReleasesIndex") {
              id
              uri
              title
              ... on Craft_pressReleasesIndex_pressReleasesIndex_Entry {
                pageTitle
              }
              seomatic {
                  metaTitleContainer
                  metaTagContainer
                  metaLinkContainer
                  metaScriptContainer
                  metaJsonLdContainer
                  metaSiteVarsContainer
                  frontendTemplateContainer
                }
            }
            categories(
              group: "pressReleaseCategory"
              relatedToEntries: {section: "pressRelease"}
              orderBy: "title"
            ) {
              id
              title
              value: id
            }
            pressReleases: entries(section: "pressRelease") {
              ... on Craft_pressRelease_default_Entry {
                key: id
                title
                uri
                pressReleaseDate
                pressReleaseCategory {
                  id
                  title
                }
              }
            }
          }
        }
      `)

    const [ageSort, setAgeSort] = useState('dsc')
    const [pressReleaseCategory, setPressReleaseCategory] = useState(null)
    const [pressReleaseQuery, setPressReleaseQuery] = useState(null)

    const renderPressReleases = (pressReleases) => {
        return pressReleases.map(pressRelease => (
            <li><a href={`/${pressRelease.uri}`}>{pressRelease.title}</a></li>
        ))
    }

    const getPressReleases = (pressReleases) => {

        // Sort by age
        if (ageSort === 'asc') {
            pressReleases.sort((a,b) => {
                return new Date(a.pressReleaseDate) - new Date(b.pressReleaseDate)
            })
        } else if (ageSort === 'dsc') {
            pressReleases.sort((a,b) => {
                return new Date(b.pressReleaseDate) - new Date(a.pressReleaseDate)
            })
        }

        // Filter by category
        if (pressReleaseCategory) {
            pressReleases = pressReleases.filter(pressRelease => {
                return pressRelease.pressReleaseCategory.some(category => category.id === pressReleaseCategory)
            })
        }

        // Filter by title
        if (pressReleaseQuery) {
            pressReleases = pressReleases.filter(pressRelease => {
                return pressRelease.title.toLowerCase().indexOf(pressReleaseQuery.toLowerCase()) > -1
            })
        }

        return renderPressReleases(pressReleases)
    }

    const changeAgeSort = (event) => {
        setAgeSort(event.target.value)
    }

    const changePressReleaseCategory = (event) => {
        setPressReleaseCategory(event.target.value)
    }

    const searchPressReleases = (event) => {
        let query = event.target.value

        if (query) {
            setPressReleaseQuery(query)
        } else {
            setPressReleaseQuery(false)
        }
    }

    // Add All option to category select
    if (!data.craft.categories.some(category => category.id === null)) {
        data.craft.categories.unshift({
            title: "All Categories",
            value: "",
            id: null
        })
    }

    return (
        <InnerLayout seomatic={data.craft.page.seomatic} entryId={data.craft.page.id}>
            <div className="midd press-releases-page">

                <PageTitle title={data.craft.page.pageTitle} />

                <div className="content-page">
                    <div className="container-small">

                        <div className="content-colum-row padding-top-none">
                            <div className="sidebar">
                                <AboutNavigation active={data.craft.page.uri}/>
                            </div>
                            <div className="content-right">
                                <h3>Press & Book Media Releases</h3>
                                <p>View latest press releases from Chicken Soup for the Soul.</p>
                                <p>(Looking for <a
                                    href="https://chickensouppets.com/press-releases" rel="noreferrer">Chicken Soup for the Soul Pet Food press releases</a>?)</p>

                                <div className="search-block">
                                    <input id="press-release-search" type="text" placeholder="Search for a Press Release Title" onChange={searchPressReleases}/>
                                </div>

                                <div className="filters">
                                    <div className="filter-column">
                                        <Select changeHandler={changeAgeSort} classes="age-sort-select" options={[
                                            {
                                                title: "Newest",
                                                value: "dsc",
                                                selected: true
                                            }, {
                                                title: "Oldest",
                                                value: "asc"
                                            }
                                        ]}></Select>
                                    </div>
                                    <div className="filter-column">
                                        <Select changeHandler={changePressReleaseCategory} classes="category-select" options={data.craft.categories}></Select>
                                    </div>
                                </div>

                                <ul className="press-releases">
                                    {getPressReleases(data.craft.pressReleases)}
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </InnerLayout>
    )
}

export default PressReleasesPage
